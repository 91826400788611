<template>
  <div>
    <!-- <button id="zoomIn">+</button>
    <button id="zoomOut">-</button> -->

    <canvas ref="can" id="canvas" width="2000" height="600"> </canvas>
    <span ref="toolTip" class="toolTip">ToolTip</span>
    <!-- <div id="canvasContainer">
      <canvas ref="can" width="1080" height="600"></canvas>
    </div> -->
  </div>

  <!-- <div class="container">
    <canvas ref="can" width="1080" height="1080"></canvas>
  </div> -->
  <!-- <div class="container">
    <panZoom>
      <div class="row">
        <div class="col main-content">
          <div class="card main-content-inner"></div>
        </div>
      </div>
    </panZoom>
  </div> -->
</template>

<script>
import { fabric } from "fabric";
export default {
  data() {
    return {};
  },
  components: {
    fabric,
  },
  mounted() {
    let dummyJson = {
      objects: [
        {
          type: "group",
          originX: "left",
          originY: "top",
          left: 129.72,
          top: 101.87,
          width: 266,
          height: 173.6,
          fill: "rgb(0,0,0)",
          stroke: null,
          strokeWidth: 0,
          strokeDashArray: null,
          strokeLineCap: "butt",
          strokeLineJoin: "miter",
          strokeMiterLimit: 10,
          scaleX: 1,
          scaleY: 1,
          angle: 30.2,
          flipX: false,
          flipY: false,
          opacity: 1,
          shadow: null,
          visible: true,
          clipTo: null,
          backgroundColor: "",
          fillRule: "nonzero",
          globalCompositeOperation: "source-over",
          transformMatrix: null,
          skewX: 0,
          skewY: 0,
          objects: [
            {
              type: "rect",
              originX: "left",
              originY: "top",
              left: -133,
              top: -86.8,
              width: 265,
              height: 172.6,
              fill: "transparent",
              stroke: "transparent",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              rx: 0,
              ry: 0,
              groupId: 6,
              seatType: "Normal",
            },
            {
              type: "i-text",
              originX: "center",
              originY: "top",
              left: -0.5,
              top: -76.8,
              width: 85.57,
              height: 22.6,
              fill: "rgb(0,0,0)",
              stroke: null,
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              text: "NORMAL",
              fontSize: 20,
              fontWeight: "normal",
              fontFamily: "sans-serif",
              fontStyle: "",
              lineHeight: 1.16,
              textDecoration: "",
              textAlign: "left",
              textBackgroundColor: "",
              charSpacing: 0,
              styles: {},
              groupId: 6,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -113,
              top: -34.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "A",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -88,
              top: -34.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "A",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: -34.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "A",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: -34.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "A",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: -34.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "A",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: -34.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "A",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: -34.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "A",
              colName: 7,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: -34.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "A",
              colName: 8,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 87,
              top: -34.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "A",
              colName: 9,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 112,
              top: -34.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "A",
              colName: 10,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -113,
              top: -9.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "B",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -88,
              top: -9.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "B",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: -9.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "B",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: -9.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "B",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: -9.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "B",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: -9.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "B",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: -9.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "B",
              colName: 7,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: -9.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "B",
              colName: 8,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 87,
              top: -9.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "B",
              colName: 9,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 112,
              top: -9.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "B",
              colName: 10,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -113,
              top: 15.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "C",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -88,
              top: 15.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "C",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: 15.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "C",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: 15.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "C",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: 15.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "C",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: 15.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "C",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: 15.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "C",
              colName: 7,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: 15.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "C",
              colName: 8,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 87,
              top: 15.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "C",
              colName: 9,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 112,
              top: 15.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "C",
              colName: 10,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -113,
              top: 40.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "D",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -88,
              top: 40.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "D",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: 40.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "D",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: 40.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "D",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: 40.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "D",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: 40.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "D",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: 40.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "D",
              colName: 7,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: 40.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "D",
              colName: 8,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 87,
              top: 40.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "D",
              colName: 9,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 112,
              top: 40.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "D",
              colName: 10,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -113,
              top: 65.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "E",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -88,
              top: 65.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "E",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: 65.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "E",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: 65.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "E",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: 65.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "E",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: 65.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "E",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: 65.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "E",
              colName: 7,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: 65.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "E",
              colName: 8,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 87,
              top: 65.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "E",
              colName: 9,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 112,
              top: 65.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 6,
              rowName: "E",
              colName: 10,
              deleted: false,
            },
          ],
          sectionType: "seating",
          rows: "5",
          cols: "10",
        },
        {
          type: "group",
          originX: "left",
          originY: "top",
          left: 126.66,
          top: 291.63,
          width: 166,
          height: 173.6,
          fill: "rgb(0,0,0)",
          stroke: null,
          strokeWidth: 0,
          strokeDashArray: null,
          strokeLineCap: "butt",
          strokeLineJoin: "miter",
          strokeMiterLimit: 10,
          scaleX: 1,
          scaleY: 1,
          angle: 31.05,
          flipX: false,
          flipY: false,
          opacity: 1,
          shadow: null,
          visible: true,
          clipTo: null,
          backgroundColor: "",
          fillRule: "nonzero",
          globalCompositeOperation: "source-over",
          transformMatrix: null,
          skewX: 0,
          skewY: 0,
          objects: [
            {
              type: "rect",
              originX: "left",
              originY: "top",
              left: -83,
              top: -86.8,
              width: 165,
              height: 172.6,
              fill: "transparent",
              stroke: "transparent",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              rx: 0,
              ry: 0,
              groupId: 7,
              seatType: "Normal",
            },
            {
              type: "i-text",
              originX: "center",
              originY: "top",
              left: -0.5,
              top: -76.8,
              width: 96.69,
              height: 22.6,
              fill: "rgb(0,0,0)",
              stroke: null,
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              text: "NORMAL1",
              fontSize: 20,
              fontWeight: "normal",
              fontFamily: "sans-serif",
              fontStyle: "",
              lineHeight: 1.16,
              textDecoration: "",
              textAlign: "left",
              textBackgroundColor: "",
              charSpacing: 0,
              styles: {},
              groupId: 7,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: -34.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 7,
              rowName: "A",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: -34.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 7,
              rowName: "A",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: -34.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 7,
              rowName: "A",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: -34.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 7,
              rowName: "A",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: -34.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 7,
              rowName: "A",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: -34.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 7,
              rowName: "A",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: -9.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 7,
              rowName: "B",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: -9.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 7,
              rowName: "B",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: -9.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 7,
              rowName: "B",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: -9.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 7,
              rowName: "B",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: -9.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 7,
              rowName: "B",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: -9.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 7,
              rowName: "B",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: 15.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 7,
              rowName: "C",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: 15.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 7,
              rowName: "C",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: 15.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 7,
              rowName: "C",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: 15.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 7,
              rowName: "C",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: 15.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 7,
              rowName: "C",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: 15.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 7,
              rowName: "C",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: 40.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 7,
              rowName: "D",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: 40.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 7,
              rowName: "D",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: 40.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 7,
              rowName: "D",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: 40.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 7,
              rowName: "D",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: 40.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 7,
              rowName: "D",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: 40.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 7,
              rowName: "D",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: 65.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 7,
              rowName: "E",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: 65.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 7,
              rowName: "E",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: 65.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 7,
              rowName: "E",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: 65.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 7,
              rowName: "E",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: 65.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 7,
              rowName: "E",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: 65.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 7,
              rowName: "E",
              colName: 6,
              deleted: false,
            },
          ],
          sectionType: "seating",
          rows: "5",
          cols: "6",
        },
        {
          type: "group",
          originX: "left",
          originY: "top",
          left: 699.87,
          top: 231.75,
          width: 266,
          height: 298.6,
          fill: "rgb(0,0,0)",
          stroke: null,
          strokeWidth: 0,
          strokeDashArray: null,
          strokeLineCap: "butt",
          strokeLineJoin: "miter",
          strokeMiterLimit: 10,
          scaleX: 1,
          scaleY: 1,
          angle: 333.9,
          flipX: false,
          flipY: false,
          opacity: 1,
          shadow: null,
          visible: true,
          clipTo: null,
          backgroundColor: "",
          fillRule: "nonzero",
          globalCompositeOperation: "source-over",
          transformMatrix: null,
          skewX: 0,
          skewY: 0,
          objects: [
            {
              type: "rect",
              originX: "left",
              originY: "top",
              left: -133,
              top: -149.3,
              width: 265,
              height: 297.6,
              fill: "transparent",
              stroke: "transparent",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              rx: 0,
              ry: 0,
              groupId: 10,
              seatType: "Normal",
            },
            {
              type: "i-text",
              originX: "center",
              originY: "top",
              left: -0.5,
              top: -139.3,
              width: 64.45,
              height: 22.6,
              fill: "rgb(0,0,0)",
              stroke: null,
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              text: "Normal",
              fontSize: 20,
              fontWeight: "normal",
              fontFamily: "sans-serif",
              fontStyle: "",
              lineHeight: 1.16,
              textDecoration: "",
              textAlign: "left",
              textBackgroundColor: "",
              charSpacing: 0,
              styles: {},
              groupId: 10,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -113,
              top: -96.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "A",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -88,
              top: -96.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "A",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: -96.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "A",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: -96.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "A",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: -96.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "A",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: -96.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "A",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: -96.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "A",
              colName: 7,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: -96.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "A",
              colName: 8,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 87,
              top: -96.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "A",
              colName: 9,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 112,
              top: -96.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "A",
              colName: 10,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -113,
              top: -71.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "B",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -88,
              top: -71.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "B",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: -71.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "B",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: -71.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "B",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: -71.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "B",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: -71.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "B",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: -71.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "B",
              colName: 7,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: -71.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "B",
              colName: 8,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 87,
              top: -71.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "B",
              colName: 9,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 112,
              top: -71.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "B",
              colName: 10,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -113,
              top: -46.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "C",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -88,
              top: -46.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "C",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: -46.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "C",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: -46.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "C",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: -46.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "C",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: -46.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "C",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: -46.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "C",
              colName: 7,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: -46.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "C",
              colName: 8,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 87,
              top: -46.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "C",
              colName: 9,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 112,
              top: -46.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "C",
              colName: 10,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -113,
              top: -21.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "D",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -88,
              top: -21.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "D",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: -21.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "D",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: -21.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "D",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: -21.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "D",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: -21.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "D",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: -21.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "D",
              colName: 7,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: -21.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "D",
              colName: 8,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 87,
              top: -21.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "D",
              colName: 9,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 112,
              top: -21.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "D",
              colName: 10,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -113,
              top: 3.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "E",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -88,
              top: 3.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "E",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: 3.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "E",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: 3.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "E",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: 3.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "E",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: 3.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "E",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: 3.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "E",
              colName: 7,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: 3.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "E",
              colName: 8,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 87,
              top: 3.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "E",
              colName: 9,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 112,
              top: 3.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "E",
              colName: 10,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -113,
              top: 28.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "F",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -88,
              top: 28.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "F",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: 28.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "F",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: 28.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "F",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: 28.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "F",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: 28.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "F",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: 28.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "F",
              colName: 7,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: 28.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "F",
              colName: 8,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 87,
              top: 28.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "F",
              colName: 9,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 112,
              top: 28.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "F",
              colName: 10,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -113,
              top: 53.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "G",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -88,
              top: 53.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "G",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: 53.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "G",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: 53.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "G",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: 53.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "G",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: 53.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "G",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: 53.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "G",
              colName: 7,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: 53.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "G",
              colName: 8,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 87,
              top: 53.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "G",
              colName: 9,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 112,
              top: 53.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "G",
              colName: 10,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -113,
              top: 78.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "H",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -88,
              top: 78.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "H",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: 78.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "H",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: 78.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "H",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: 78.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "H",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: 78.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "H",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: 78.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "H",
              colName: 7,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: 78.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "H",
              colName: 8,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 87,
              top: 78.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "H",
              colName: 9,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 112,
              top: 78.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "H",
              colName: 10,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -113,
              top: 103.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "I",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -88,
              top: 103.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "I",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: 103.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "I",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: 103.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "I",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: 103.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "I",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: 103.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "I",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: 103.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "I",
              colName: 7,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: 103.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "I",
              colName: 8,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 87,
              top: 103.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "I",
              colName: 9,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 112,
              top: 103.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "I",
              colName: 10,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -113,
              top: 128.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "J",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -88,
              top: 128.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "J",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: 128.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "J",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: 128.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "J",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: 128.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "J",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: 128.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "J",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: 128.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "J",
              colName: 7,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: 128.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "J",
              colName: 8,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 87,
              top: 128.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "J",
              colName: 9,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 112,
              top: 128.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 10,
              rowName: "J",
              colName: 10,
              deleted: false,
            },
          ],
          sectionType: "seating",
          rows: "10",
          cols: "10",
        },
        {
          type: "group",
          originX: "left",
          originY: "top",
          left: 927,
          top: 119.07,
          width: 66.23,
          height: 298.6,
          fill: "rgb(0,0,0)",
          stroke: null,
          strokeWidth: 0,
          strokeDashArray: null,
          strokeLineCap: "butt",
          strokeLineJoin: "miter",
          strokeMiterLimit: 10,
          scaleX: 1,
          scaleY: 1,
          angle: 329.72,
          flipX: false,
          flipY: false,
          opacity: 1,
          shadow: null,
          visible: true,
          clipTo: null,
          backgroundColor: "",
          fillRule: "nonzero",
          globalCompositeOperation: "source-over",
          transformMatrix: null,
          skewX: 0,
          skewY: 0,
          objects: [
            {
              type: "rect",
              originX: "left",
              originY: "top",
              left: -32.89,
              top: -149.3,
              width: 65,
              height: 297.6,
              fill: "transparent",
              stroke: "transparent",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              rx: 0,
              ry: 0,
              groupId: 11,
              seatType: "Normal",
            },
            {
              type: "i-text",
              originX: "center",
              originY: "top",
              left: -0.39,
              top: -139.3,
              width: 64.45,
              height: 22.6,
              fill: "rgb(0,0,0)",
              stroke: null,
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              text: "Normal",
              fontSize: 20,
              fontWeight: "normal",
              fontFamily: "sans-serif",
              fontStyle: "",
              lineHeight: 1.16,
              textDecoration: "",
              textAlign: "left",
              textBackgroundColor: "",
              charSpacing: 0,
              styles: {},
              groupId: 11,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -12.89,
              top: -96.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 11,
              rowName: "A",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12.11,
              top: -96.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 11,
              rowName: "A",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -12.89,
              top: -71.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 11,
              rowName: "B",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12.11,
              top: -71.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 11,
              rowName: "B",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -12.89,
              top: -46.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 11,
              rowName: "C",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12.11,
              top: -46.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 11,
              rowName: "C",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -12.89,
              top: -21.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 11,
              rowName: "D",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12.11,
              top: -21.7,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 11,
              rowName: "D",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -12.89,
              top: 3.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 11,
              rowName: "E",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12.11,
              top: 3.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 11,
              rowName: "E",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -12.89,
              top: 28.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 11,
              rowName: "F",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12.11,
              top: 28.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 11,
              rowName: "F",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -12.89,
              top: 53.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 11,
              rowName: "G",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12.11,
              top: 53.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 11,
              rowName: "G",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -12.89,
              top: 78.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 11,
              rowName: "H",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12.11,
              top: 78.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 11,
              rowName: "H",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -12.89,
              top: 103.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 11,
              rowName: "I",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12.11,
              top: 103.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 11,
              rowName: "I",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -12.89,
              top: 128.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 11,
              rowName: "J",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12.11,
              top: 128.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 11,
              rowName: "J",
              colName: 2,
              deleted: false,
            },
          ],
          sectionType: "seating",
          rows: "10",
          cols: "2",
        },
        {
          type: "group",
          originX: "left",
          originY: "top",
          left: 851.26,
          top: 525.82,
          width: 266,
          height: 98.6,
          fill: "rgb(0,0,0)",
          stroke: null,
          strokeWidth: 0,
          strokeDashArray: null,
          strokeLineCap: "butt",
          strokeLineJoin: "miter",
          strokeMiterLimit: 10,
          scaleX: 1,
          scaleY: 1,
          angle: 327.65,
          flipX: false,
          flipY: false,
          opacity: 1,
          shadow: null,
          visible: true,
          clipTo: null,
          backgroundColor: "",
          fillRule: "nonzero",
          globalCompositeOperation: "source-over",
          transformMatrix: null,
          skewX: 0,
          skewY: 0,
          objects: [
            {
              type: "rect",
              originX: "left",
              originY: "top",
              left: -133,
              top: -49.3,
              width: 265,
              height: 97.6,
              fill: "transparent",
              stroke: "transparent",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              rx: 0,
              ry: 0,
              groupId: 12,
              seatType: "Normal",
            },
            {
              type: "i-text",
              originX: "center",
              originY: "top",
              left: -0.5,
              top: -39.3,
              width: 64.45,
              height: 22.6,
              fill: "rgb(0,0,0)",
              stroke: null,
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              text: "Normal",
              fontSize: 20,
              fontWeight: "normal",
              fontFamily: "sans-serif",
              fontStyle: "",
              lineHeight: 1.16,
              textDecoration: "",
              textAlign: "left",
              textBackgroundColor: "",
              charSpacing: 0,
              styles: {},
              groupId: 12,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -113,
              top: 3.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 12,
              rowName: "A",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -88,
              top: 3.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 12,
              rowName: "A",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: 3.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 12,
              rowName: "A",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: 3.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 12,
              rowName: "A",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: 3.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 12,
              rowName: "A",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: 3.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 12,
              rowName: "A",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: 3.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 12,
              rowName: "A",
              colName: 7,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: 3.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 12,
              rowName: "A",
              colName: 8,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 87,
              top: 3.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 12,
              rowName: "A",
              colName: 9,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 112,
              top: 3.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 12,
              rowName: "A",
              colName: 10,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -113,
              top: 28.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 12,
              rowName: "B",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -88,
              top: 28.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 12,
              rowName: "B",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: 28.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 12,
              rowName: "B",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: 28.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 12,
              rowName: "B",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: 28.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 12,
              rowName: "B",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: 28.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 12,
              rowName: "B",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: 28.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 12,
              rowName: "B",
              colName: 7,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: 28.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 12,
              rowName: "B",
              colName: 8,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 87,
              top: 28.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 12,
              rowName: "B",
              colName: 9,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 112,
              top: 28.3,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 12,
              rowName: "B",
              colName: 10,
              deleted: false,
            },
          ],
          sectionType: "seating",
          rows: "2",
          cols: "10",
        },
        {
          type: "group",
          originX: "left",
          originY: "top",
          left: 236.8,
          top: 22,
          width: 612,
          height: 96,
          fill: "rgb(0,0,0)",
          stroke: null,
          strokeWidth: 0,
          strokeDashArray: null,
          strokeLineCap: "butt",
          strokeLineJoin: "miter",
          strokeMiterLimit: 10,
          scaleX: 1,
          scaleY: 1,
          angle: 0,
          flipX: false,
          flipY: false,
          opacity: 1,
          shadow: null,
          visible: true,
          clipTo: null,
          backgroundColor: "",
          fillRule: "nonzero",
          globalCompositeOperation: "source-over",
          transformMatrix: null,
          skewX: 0,
          skewY: 0,
          objects: [
            {
              type: "rect",
              originX: "left",
              originY: "top",
              left: -306,
              top: -48,
              width: 611,
              height: 95,
              fill: "#ffffff",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              rx: 0,
              ry: 0,
              price: 0,
              groupId: 14,
            },
            {
              type: "i-text",
              originX: "center",
              originY: "top",
              left: -0.5,
              top: -0.5,
              width: 52.27,
              height: 22.6,
              fill: "rgb(0,0,0)",
              stroke: null,
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              text: "Stage",
              fontSize: 20,
              fontWeight: "normal",
              fontFamily: "sans-serif",
              fontStyle: "",
              lineHeight: 1.16,
              textDecoration: "",
              textAlign: "left",
              textBackgroundColor: "",
              charSpacing: 0,
              styles: {},
              groupId: 14,
            },
          ],
          sectionType: "generalArea",
        },
        {
          type: "group",
          originX: "left",
          originY: "top",
          left: 282.1,
          top: 227.42,
          width: 123.81,
          height: 305.76,
          fill: "rgb(0,0,0)",
          stroke: null,
          strokeWidth: 0,
          strokeDashArray: null,
          strokeLineCap: "butt",
          strokeLineJoin: "miter",
          strokeMiterLimit: 10,
          scaleX: 1,
          scaleY: 1,
          angle: 0,
          flipX: false,
          flipY: false,
          opacity: 1,
          shadow: null,
          visible: true,
          clipTo: null,
          backgroundColor: "",
          fillRule: "nonzero",
          globalCompositeOperation: "source-over",
          transformMatrix: null,
          skewX: 0,
          skewY: 0,
          objects: [
            {
              type: "rect",
              originX: "left",
              originY: "top",
              left: -2.79,
              top: -152.88,
              width: 65,
              height: 297.6,
              fill: "transparent",
              stroke: "transparent",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 11.42,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              rx: 0,
              ry: 0,
              groupId: 8,
              seatType: "Normal",
            },
            {
              type: "i-text",
              originX: "center",
              originY: "top",
              left: 27.09,
              top: -136.64,
              width: 43.36,
              height: 22.6,
              fill: "rgb(0,0,0)",
              stroke: null,
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 11.42,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              text: "VIP1",
              fontSize: 20,
              fontWeight: "normal",
              fontFamily: "sans-serif",
              fontStyle: "",
              lineHeight: 1.16,
              textDecoration: "",
              textAlign: "left",
              textBackgroundColor: "",
              charSpacing: 0,
              styles: {},
              groupId: 8,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 6.4,
              top: -97.36,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "gray",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 11.42,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: false,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 8,
              rowName: "A",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 30.91,
              top: -92.41,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 11.42,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 8,
              rowName: "A",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 1.45,
              top: -72.85,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 11.42,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 8,
              rowName: "B",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 25.96,
              top: -67.91,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 11.42,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 8,
              rowName: "B",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -3.5,
              top: -48.35,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 11.42,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 8,
              rowName: "C",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 21.01,
              top: -43.4,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 11.42,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 8,
              rowName: "C",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -8.45,
              top: -23.84,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 11.42,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 8,
              rowName: "D",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 16.06,
              top: -18.89,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 11.42,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 8,
              rowName: "D",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13.4,
              top: 0.66,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 11.42,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 8,
              rowName: "E",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 11.11,
              top: 5.61,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 11.42,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 8,
              rowName: "E",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -18.35,
              top: 25.17,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 11.42,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 8,
              rowName: "F",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 6.16,
              top: 30.12,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 11.42,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 8,
              rowName: "F",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -23.29,
              top: 49.67,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 11.42,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 8,
              rowName: "G",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 1.21,
              top: 54.62,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 11.42,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 8,
              rowName: "G",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -28.24,
              top: 74.18,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 11.42,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 8,
              rowName: "H",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -3.74,
              top: 79.13,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 11.42,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 8,
              rowName: "H",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -33.19,
              top: 98.68,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 11.42,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 8,
              rowName: "I",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -8.69,
              top: 103.63,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 11.42,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 8,
              rowName: "I",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38.14,
              top: 123.19,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 11.42,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 8,
              rowName: "J",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13.64,
              top: 128.14,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 11.42,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 8,
              rowName: "J",
              colName: 2,
              deleted: false,
            },
          ],
        },
        {
          type: "group",
          originX: "left",
          originY: "top",
          left: 649.68,
          top: 228.5,
          width: 118.65,
          height: 305.6,
          fill: "rgb(0,0,0)",
          stroke: null,
          strokeWidth: 0,
          strokeDashArray: null,
          strokeLineCap: "butt",
          strokeLineJoin: "miter",
          strokeMiterLimit: 10,
          scaleX: 1,
          scaleY: 1,
          angle: 0,
          flipX: false,
          flipY: false,
          opacity: 1,
          shadow: null,
          visible: true,
          clipTo: null,
          backgroundColor: "",
          fillRule: "nonzero",
          globalCompositeOperation: "source-over",
          transformMatrix: null,
          skewX: 0,
          skewY: 0,
          objects: [
            {
              type: "rect",
              originX: "left",
              originY: "top",
              left: -59.32,
              top: -140.93,
              width: 65,
              height: 297.6,
              fill: "transparent",
              stroke: "transparent",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: -10.37,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              rx: 0,
              ry: 0,
              groupId: 9,
              seatType: "Normal",
            },
            {
              type: "i-text",
              originX: "center",
              originY: "top",
              left: -25.56,
              top: -136.94,
              width: 43.36,
              height: 22.6,
              fill: "rgb(0,0,0)",
              stroke: null,
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: -10.37,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              text: "VIP2",
              fontSize: 20,
              fontWeight: "normal",
              fontFamily: "sans-serif",
              fontStyle: "",
              lineHeight: 1.16,
              textDecoration: "",
              textAlign: "left",
              textBackgroundColor: "",
              charSpacing: 0,
              styles: {},
              groupId: 9,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -30.19,
              top: -92.78,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "gray",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: -10.37,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: false,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 9,
              rowName: "A",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -5.59,
              top: -97.28,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: -10.37,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 9,
              rowName: "A",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -25.69,
              top: -68.19,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: -10.37,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 9,
              rowName: "B",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -1.1,
              top: -72.69,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: -10.37,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 9,
              rowName: "B",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -21.19,
              top: -43.6,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: -10.37,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 9,
              rowName: "C",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 3.4,
              top: -48.1,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: -10.37,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 9,
              rowName: "C",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -16.69,
              top: -19.01,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: -10.37,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 9,
              rowName: "D",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 7.9,
              top: -23.5,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: -10.37,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 9,
              rowName: "D",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -12.19,
              top: 5.59,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: -10.37,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 9,
              rowName: "E",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12.4,
              top: 1.09,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: -10.37,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 9,
              rowName: "E",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -7.7,
              top: 30.18,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: -10.37,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 9,
              rowName: "F",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 16.9,
              top: 25.68,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: -10.37,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 9,
              rowName: "F",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -3.2,
              top: 54.77,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: -10.37,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 9,
              rowName: "G",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 21.39,
              top: 50.27,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: -10.37,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 9,
              rowName: "G",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 1.3,
              top: 79.36,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: -10.37,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 9,
              rowName: "H",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 25.89,
              top: 74.86,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: -10.37,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 9,
              rowName: "H",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 5.8,
              top: 103.95,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: -10.37,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 9,
              rowName: "I",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 30.39,
              top: 99.46,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: -10.37,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 9,
              rowName: "I",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 10.3,
              top: 128.55,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: -10.37,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 9,
              rowName: "J",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 34.89,
              top: 124.05,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: -10.37,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 250,
              groupId: 9,
              rowName: "J",
              colName: 2,
              deleted: false,
            },
          ],
        },
        {
          type: "group",
          originX: "left",
          originY: "top",
          left: 397,
          top: 239,
          width: 266,
          height: 423.6,
          fill: "rgb(0,0,0)",
          stroke: null,
          strokeWidth: 0,
          strokeDashArray: null,
          strokeLineCap: "butt",
          strokeLineJoin: "miter",
          strokeMiterLimit: 10,
          scaleX: 1,
          scaleY: 1,
          angle: 0,
          flipX: false,
          flipY: false,
          opacity: 1,
          shadow: null,
          visible: true,
          clipTo: null,
          backgroundColor: "",
          fillRule: "nonzero",
          globalCompositeOperation: "source-over",
          transformMatrix: null,
          skewX: 0,
          skewY: 0,
          objects: [
            {
              type: "rect",
              originX: "left",
              originY: "top",
              left: -133,
              top: -211.8,
              width: 265,
              height: 422.6,
              fill: "transparent",
              stroke: "transparent",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              rx: 0,
              ry: 0,
              groupId: 5,
              seatType: "VIP",
            },
            {
              type: "i-text",
              originX: "center",
              originY: "top",
              left: -0.5,
              top: -201.8,
              width: 32.24,
              height: 22.6,
              fill: "rgb(0,0,0)",
              stroke: null,
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              text: "VIP",
              fontSize: 20,
              fontWeight: "normal",
              fontFamily: "sans-serif",
              fontStyle: "",
              lineHeight: 1.16,
              textDecoration: "",
              textAlign: "left",
              textBackgroundColor: "",
              charSpacing: 0,
              styles: {},
              groupId: 5,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -113,
              top: -159.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "A",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -88,
              top: -159.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "A",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: -159.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "A",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: -159.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "A",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: -159.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "gray",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: false,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "A",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: -159.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "A",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: -159.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "A",
              colName: 7,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: -159.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "A",
              colName: 8,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 87,
              top: -159.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "A",
              colName: 9,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 112,
              top: -159.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "A",
              colName: 10,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -113,
              top: -134.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "B",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -88,
              top: -134.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "B",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: -134.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "B",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: -134.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "B",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: -134.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "B",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: -134.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "B",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: -134.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "B",
              colName: 7,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: -134.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "B",
              colName: 8,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 87,
              top: -134.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "B",
              colName: 9,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 112,
              top: -134.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "B",
              colName: 10,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -113,
              top: -109.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "C",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -88,
              top: -109.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "C",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: -109.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "C",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: -109.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "C",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: -109.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "C",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: -109.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "C",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: -109.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "C",
              colName: 7,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: -109.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "C",
              colName: 8,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 87,
              top: -109.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "C",
              colName: 9,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 112,
              top: -109.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "C",
              colName: 10,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -113,
              top: -84.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "D",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -88,
              top: -84.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "D",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: -84.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "D",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: -84.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "D",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: -84.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "D",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: -84.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "D",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: -84.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "D",
              colName: 7,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: -84.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "D",
              colName: 8,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 87,
              top: -84.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "D",
              colName: 9,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 112,
              top: -84.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "D",
              colName: 10,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -113,
              top: -59.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "E",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -88,
              top: -59.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "E",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: -59.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "E",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: -59.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "E",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: -59.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "E",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: -59.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "E",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: -59.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "E",
              colName: 7,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: -59.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "E",
              colName: 8,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 87,
              top: -59.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "E",
              colName: 9,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 112,
              top: -59.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "E",
              colName: 10,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -113,
              top: -34.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "F",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -88,
              top: -34.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "F",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: -34.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "F",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: -34.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "F",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: -34.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "F",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: -34.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "F",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: -34.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "F",
              colName: 7,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: -34.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "F",
              colName: 8,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 87,
              top: -34.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "F",
              colName: 9,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 112,
              top: -34.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "F",
              colName: 10,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -113,
              top: -9.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "G",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -88,
              top: -9.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "G",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: -9.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "G",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: -9.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "G",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: -9.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "G",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: -9.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "G",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: -9.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "G",
              colName: 7,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: -9.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "G",
              colName: 8,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 87,
              top: -9.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "G",
              colName: 9,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 112,
              top: -9.2,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "G",
              colName: 10,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -113,
              top: 15.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "H",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -88,
              top: 15.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "H",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: 15.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "H",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: 15.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "H",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: 15.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "H",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: 15.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "H",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: 15.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "H",
              colName: 7,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: 15.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "H",
              colName: 8,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 87,
              top: 15.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "H",
              colName: 9,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 112,
              top: 15.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "H",
              colName: 10,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -113,
              top: 40.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "I",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -88,
              top: 40.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "I",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: 40.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "I",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: 40.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "I",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: 40.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "I",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: 40.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "I",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: 40.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "I",
              colName: 7,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: 40.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "I",
              colName: 8,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 87,
              top: 40.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "I",
              colName: 9,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 112,
              top: 40.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "I",
              colName: 10,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -113,
              top: 65.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "J",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -88,
              top: 65.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "J",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: 65.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "J",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: 65.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "J",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: 65.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "J",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: 65.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "J",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: 65.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "J",
              colName: 7,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: 65.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "J",
              colName: 8,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 87,
              top: 65.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "J",
              colName: 9,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 112,
              top: 65.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "J",
              colName: 10,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -113,
              top: 90.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "K",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -88,
              top: 90.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "K",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: 90.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "K",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: 90.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "K",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: 90.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "K",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: 90.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "K",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: 90.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "K",
              colName: 7,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: 90.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "K",
              colName: 8,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 87,
              top: 90.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "K",
              colName: 9,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 112,
              top: 90.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "K",
              colName: 10,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -113,
              top: 115.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "L",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -88,
              top: 115.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "L",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: 115.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "L",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: 115.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "L",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: 115.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "L",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: 115.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "L",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: 115.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "L",
              colName: 7,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: 115.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "L",
              colName: 8,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 87,
              top: 115.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "L",
              colName: 9,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 112,
              top: 115.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "L",
              colName: 10,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -113,
              top: 140.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "M",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -88,
              top: 140.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "M",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: 140.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "M",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: 140.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "M",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: 140.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "M",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: 140.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "M",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: 140.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "M",
              colName: 7,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: 140.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "M",
              colName: 8,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 87,
              top: 140.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "M",
              colName: 9,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 112,
              top: 140.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "M",
              colName: 10,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -113,
              top: 165.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "N",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -88,
              top: 165.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "N",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: 165.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "N",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: 165.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "N",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: 165.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "N",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: 165.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "N",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: 165.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "N",
              colName: 7,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: 165.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "N",
              colName: 8,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 87,
              top: 165.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "N",
              colName: 9,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 112,
              top: 165.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "N",
              colName: 10,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -113,
              top: 190.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "O",
              colName: 1,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -88,
              top: 190.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "O",
              colName: 2,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -63,
              top: 190.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "O",
              colName: 3,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -38,
              top: 190.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "O",
              colName: 4,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: -13,
              top: 190.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "O",
              colName: 5,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 12,
              top: 190.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "O",
              colName: 6,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 37,
              top: 190.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "O",
              colName: 7,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 62,
              top: 190.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "O",
              colName: 8,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 87,
              top: 190.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "O",
              colName: 9,
              deleted: false,
            },
            {
              type: "circle",
              originX: "center",
              originY: "center",
              left: 112,
              top: 190.8,
              width: 20,
              height: 20,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeLineJoin: "miter",
              strokeMiterLimit: 10,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              clipTo: null,
              backgroundColor: "",
              fillRule: "nonzero",
              globalCompositeOperation: "source-over",
              transformMatrix: null,
              skewX: 0,
              skewY: 0,
              radius: 10,
              startAngle: 0,
              endAngle: 6.283185307179586,
              price: 500,
              groupId: 5,
              rowName: "O",
              colName: 10,
              deleted: false,
            },
          ],
        },
      ],
    };

    let ref = this.$refs.can;
    let canvas = new fabric.Canvas(ref);
    canvas.loadFromJSON(JSON.stringify(dummyJson), function () {
      canvas.renderAll();
    });

    this.$nextTick(() => {
      canvas.forEachObject(function (item) {
        let getObjects = item.getObjects();

        for (let item1 of getObjects) {
          if (item1.type && (item1.type == "rect" || item1.type == "i-text")) {
            item1.selectable = false;
          }
        }

        item.selectable = false;
        item.dirty = true;
        item.lockMovementX = true;
        item.lockMovementY = true;
        canvas.hoverCursor = "pointer";
        item.lockRotation = true;
        item.lockUniScaling = true;
        item.lockScalingX = true;
        item.lockScalingY = true;
        canvas.item(canvas.size() - 1).hasControls = false;
      });
      canvas.renderAll();
    });

    canvas.on("mouse:down", async function (options) {
      console.log(options.target, "target");
      if (
        options.target &&
        options.target.type &&
        options.target.type == "group"
      ) {
        options.target.selectable = false;
        // let selectedGroup = canvas.getActiveObject();
        // if (selectedGroup != null) {
        //   let editGroup = selectedGroup.getObjects();
        //   selectedGroup._restoreObjectsState();
        //   canvas.remove(selectedGroup);

        //   for (let i = 0; i < editGroup.length; i++) {
        //     canvas.add(editGroup[i]);
        //     editGroup[i].dirty = true;
        //     editGroup[i].lockMovementX = true;
        //     editGroup[i].lockMovementY = true;
        //     canvas.item(canvas.size() - 1).hasControls = false;
        //   }

        //   // let delta = options.e.deltaY;
        //   let pointer = canvas.getPointer(options.e);

        //   let zoom = canvas.getZoom();
        //   console.log(zoom, "zoom", pointer);
        //   canvas.zoomToPoint({ x: pointer.x, y: pointer.y }, 1.5);
        // }
      } else if (
        options.target &&
        options.target.type &&
        options.target.type == "circle"
      ) {
        options.target.fill = "red";
        options.target.dirty = true;
        options.target.lockMovementX = true;
        options.target.lockMovementY = true;
        options.target.hoverCursor = "pointer";
        options.target.lockRotation = true;
        options.target.lockUniScaling = true;
        options.target.lockScalingX = true;
        options.target.lockScalingY = true;
        canvas.item(canvas.size() - 1).hasControls = false;
      } else if (
        options.target &&
        options.target.type &&
        options.target.type == "rect"
      ) {
        options.target.selectable = false;
        options.target.dirty = true;
        options.target.lockMovementX = true;
        options.target.lockMovementY = true;
        options.target.hoverCursor = "pointer";
        options.target.lockRotation = true;
        options.target.lockUniScaling = true;
        options.target.lockScalingX = true;
        options.target.lockScalingY = true;
        canvas.item(canvas.size() - 1).hasControls = false;
      }

      canvas.renderAll();
      canvas.forEachObject(function (item) {
        item.selectable = false;
        item.dirty = true;
        item.lockMovementX = true;
        item.lockMovementY = true;
        canvas.hoverCursor = "pointer";
        item.lockRotation = true;
        item.lockUniScaling = true;
        item.lockScalingX = true;
        item.lockScalingY = true;
        canvas.item(canvas.size() - 1).hasControls = false;
      });
      canvas.renderAll();
    });

    canvas.on("mouse:wheel", function (opt) {
      let delta = opt.e.deltaY;
      let pointer = canvas.getPointer(opt.e);
      let zoom = canvas.getZoom();
      zoom = zoom + delta / 200;
      if (zoom > 1.5) zoom = 1.5;
      if (zoom < 0.5) zoom = 0.5;
      canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
    });

    var panning = false;
    canvas.on("mouse:up", function (e) {
      panning = false;
    });

    canvas.on("mouse:down", function (e) {
      panning = true;
    });
    // canvas.on("mouse:move", function (e) {
    //   if (panning && e && e.e) {
    //     debugger;
    //     var units = 10;
    //     var delta = new fabric.Point(e.e.movementX, e.e.movementY);
    //     canvas.relativePan(delta);
    //   }
    // });

    let refTooltip = this.$refs.toolTip;

    canvas.on("mouse:over", function (e) {
      if (e.target && e.target.type === "group") {
        e.target.selectable = false;
        let selectedGroup = e.target;
        if (selectedGroup != null) {
          let editGroup = selectedGroup.getObjects();
          selectedGroup._restoreObjectsState();
          canvas.remove(selectedGroup);

          for (let i = 0; i < editGroup.length; i++) {
            canvas.add(editGroup[i]);
            editGroup[i].dirty = true;
            editGroup[i].lockMovementX = true;
            editGroup[i].lockMovementY = true;
            canvas.item(canvas.size() - 1).hasControls = false;
          }
        }
      }
    });
    // canvas.on("mouse:over", function (e) {
    //   if (e.target && e.target.type === "circle") {
    //     var p = canvas.getPointer(e.e);
    //     group.set({
    //       left: p.x,
    //       top: p.y,
    //       visible: true,
    //     });
    //     canvas.renderAll();
    //   }
    // });
    // canvas.on("mouse:out", function (e) {
    //   refTooltip.style.visibility = "hidden";
    // });
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
#canvas {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.toolTip {
  position: absolute;
  z-index: 1;
  background: rgb(119, 128, 0);
  height: 30px;
  width: 120px;
  padding: 8px;
  font-size: 13px;
  color: #fff;
  visibility: hidden;
}
</style>
